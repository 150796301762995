//CAROUSEL
let swiper;

const initSwiper = () => {
  let currentZIndex = 1;

  swiper = new Swiper('.swiper', {
    grabCursor: true,
    watchSlidesProgress: true,
    slidesPerView: 1.6,
    loop: true,
    speed: 1500,
    centeredSlides: true,
    centerInsufficientSlides: true,
    spaceBetween: -20,
    pagination: {
      clickable: true,
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    breakpoints: {
      1025: {
        slidesPerView: 1.5,
        spaceBetween: -80,
        centeredSlides: true
      }
    },
    on: {
      // eslint-disable-next-line
      progress(swiper) {
        for (let i = 0; i < swiper.slides.length; i++) {
          const slideNode = swiper.slides[i]
          const slideProgress = slideNode.progress
          const absProgress =
            Math.round(Math.abs(slideProgress) * 100) / 100
          let scale = 1 - absProgress * 0.45

          slideNode.style.transform = `scale(${scale})`
        }
      },
      // eslint-disable-next-line
      setTransition(swiper, duration) {
        for (let i = 0; i < swiper.slides.length; i += 1) {
          const slideEl = swiper.slides[i]
          slideEl.style.transitionDuration = `${duration}ms`
        }
      },
      transitionStart() {
        let slides = document.querySelectorAll(".swiper .swiper-slide")

        slides.forEach(slide => {
          slide.classList.remove('transition-ended')
        });
      },
      transitionEnd() {
        let activeId = document.querySelector(".swiper .swiper-slide-active").getAttribute("data-id");
        let activeSlide = document.querySelector(".swiper .swiper-slide-active")
        activeSlide.classList.add('transition-ended')

        let activeBlurredImage = document.querySelector(`.hero-section .blurred-image[data-id="${activeId}"]`)
        let blurredImages = document.querySelectorAll(".hero-section .blurred-image")
        let blurredImagesWrapper = document.querySelector('.blurred-images-wrapper')
        blurredImages.forEach(img => {
          img.classList.remove('active')
        });

        blurredImagesWrapper.querySelector(".overlay").classList.add('opacity-1')
        setTimeout(() => {
          activeBlurredImage.classList.add('active')
          activeBlurredImage.style.zIndex = currentZIndex
          blurredImagesWrapper.querySelector(".overlay").classList.remove('opacity-1')
        }, 250);

      },
    },
  })
}


const initMenu = () => {
  let menuOpen = false
  const headerNode = document.querySelector('header.main-header')
  const navNode = document.querySelector('.main-nav')
  const navWrapperNode = document.querySelector('.nav-wrapper')
  const navLinks = document.querySelectorAll('.nav-wrapper a')

  const handleMenuButtonClick = () => {
    if (menuOpen == true) {
      navMenu.close()
    } else {
      navMenu.open()
    }
  }

  const navMenu = {
    close: () => {
      menuOpen = false
      headerNode.classList.remove('nav-open')

      if (window.innerWidth <= 1025) {
        navNode.style.height = "0px"
      } else {
        navNode.style.height = "28px"
      }

    },
    open: () => {
      let navH = navWrapperNode.clientHeight
      navNode.style.height = `${navH}px`
      headerNode.classList.add('nav-open')
      menuOpen = true
    }
  }

  document.querySelector('.burger-icon-container').addEventListener('click', handleMenuButtonClick)
  navLinks.forEach(link => {
    link.addEventListener('click', () => {
      if (menuOpen == true && window.innerWidth < 1025) {
        navMenu.close()
      }
    })
  });
  window.addEventListener('resize', navMenu.close)
}

const initScrollTriggers = () => {
  const tl = gsap.timeline()

  //images
  const imageContainers = document.querySelectorAll('[data-reveal="expand-up"]')
  const imageNodes = document.querySelectorAll('[data-reveal="expand-up"] .parallax-img img')
  const dividerNodes = document.querySelectorAll('[data-reveal="width-expand"]')
  const fadeInUps = document.querySelectorAll('[data-reveal="fade-in-up"]')

  const customEase01 = CustomEase.create("custom", "M0,0 C0.11,0.494 0.072,0.764 0.198,0.89 0.33,1.022 0.504,1 1,1")


  tl.set(imageContainers, {
    webkitClipPath: "inset(0% 0% 100% 0%)",
    clipPath: "inset(0% 0% 100% 0%)",
  }).set(imageNodes, {
    scale: 2,
  })

  ScrollTrigger.batch(imageContainers, {
    start: "200px 90%",
    onEnter: elements => {
      gsap.to(elements, {
        webkitClipPath: "inset(0% 0% 0% 0%)",
        clipPath: "inset(0% 0% 0% 0%)",
        ease: customEase01,
        duration: 5,
      });
    }
  })

  ScrollTrigger.batch(fadeInUps, {
    start: "200px 90%",
    onEnter: elements => {
      gsap.to(elements, {
        opacity: 1,
        y: 0,
        ease: customEase01,
        duration: 3,
        stagger: 0.125
      });
    }
  })

  ScrollTrigger.batch(imageNodes, {
    start: "200px 90%",
    onEnter: elements => {
      gsap.to(elements, {
        scale: 1.2,
        ease: customEase01,
        duration: 6
      })
    }
  })


  ScrollTrigger.batch(dividerNodes, {
    start: "200px 90%",
    onEnter: elements => {
      gsap.to(elements, {
        width: "100%",
        ease: customEase01,
        duration: 4,
      })
    }
  })

}


const initAccordion = function () {
  const accordionRows = document.querySelectorAll(".accordion-row");
  const accordionSection = document.querySelector('.accordion-section')

  accordionRows.forEach(accordionRow => {
    const lineHorizontal = accordionRow.querySelector(".line-horizontal");
    const lineVertical = accordionRow.querySelector(".line-vertical");
    const accordionTexts = accordionRow.querySelectorAll(".body-p li");

    gsap.set(accordionTexts, {
      opacity: 0,
      y: -12
    });

    accordionRow.addEventListener("click", event => {
      let status = event.target.getAttribute("data-status");

      if (status == "closed") {
        event.target.querySelector(".accordion-content").style.height =
          event.target.querySelector(".accordion-content-inner").clientHeight +
          "px";

        gsap.to(lineHorizontal, {
          rotate: 180 + 45,
          transformOrigin: "50% 50%",
          ease: "Power1.easeInOut"
        });

        gsap.to(lineVertical, {
          rotate: 180 + 45,
          transformOrigin: "50% 50%",
          delay: 0.15,
          ease: "Power1.easeInOut"
        });

        gsap.to(accordionTexts, {
          opacity: 1,
          y: 0,
          ease: "Power1.easeInOut",
          stagger: 0.075
        });

        event.target.setAttribute("data-status", "open");
        accordionSection.classList.add('accordion-open')

      } else {
        event.target.querySelector(".accordion-content").style.height = "0px";

        gsap.to(lineHorizontal, {
          rotate: 0,
          ease: "Power1.easeInOut"
        });

        gsap.to(lineVertical, {
          rotate: 0,
          delay: 0.15,
          ease: "Power1.easeInOut"
        });

        gsap.to(accordionTexts, {
          opacity: 0,
          y: -12,
          ease: "Power1.easeInOut",
          stagger: -0.075,
          duration: 0.1
        });

        event.target.setAttribute("data-status", "closed");
        accordionSection.classList.remove('accordion-open')
      }
    });
  });

  var rtime;
  var timeout = false;
  var delta = 200;

  window.addEventListener("resize", () => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  //https://stackoverflow.com/questions/5489946/how-to-wait-for-the-end-of-resize-event-and-only-then-perform-an-action
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      if (document.querySelector(".accordion-row[data-status='open']")) {
        let openAccordions = document.querySelectorAll(
          ".accordion-row[data-status='open']"
        );

        openAccordions.forEach(openAccordion => {
          openAccordion.querySelector(".accordion-content").style.height =
            openAccordion.querySelector(".accordion-content-inner")
              .clientHeight + "px";
        });
      }
    }
  }

  function initHoverImage() {
    const accordionSection = document.querySelector('.accordion-section')
    const accordionWrapper = document.querySelector('.accordion-inner-wrapper')
    const hoverImageWrapper = accordionWrapper.querySelector('.hover-image-wrapper')
    const hoverImages = accordionWrapper.querySelectorAll('.hover-image')
    /* const helperText = document.querySelector('.helper-text') */



    let calculateCursorDistance = (e) => {
      if (hoverIsActive == false) {
        return
      }

      xPos = e.pageX
      yPos = e.pageY

      imageYPos = accordionSection.getBoundingClientRect().top + window.scrollY + (hoverImageWrapper.clientHeight / 2);
      imageXPos = hoverImageWrapper.getBoundingClientRect().left + (accordionWrapper.querySelector('.hover-image-wrapper').clientWidth / 2)

      xDiff = imageXPos - xPos
      yDiff = imageYPos - yPos

      translateX = -xDiff / 2
      translateY = -yDiff

      hoverImageWrapper.style.transform = `translate(${translateX}px , ${translateY}px)`
    }

    window.addEventListener('mousemove', calculateCursorDistance)

    let currentId = null;
    let hoveredId = null;
    let zIndex = 1;
    let xPos = null;
    let yPos = null;
    let imageXPos = null;
    let imageYPos = null;
    let xDiff = null;
    let translateX = null;
    let yDiff = null;
    let translateY = null;
    let hoverIsActive = false;

    //start moving image around
    accordionWrapper.addEventListener('mouseenter', () => {
      hoverIsActive = true

      gsap.to(hoverImageWrapper, {
        opacity: 1,
        duration: 0.2
      })
    })

    //stop moving image around
    accordionWrapper.addEventListener('mouseleave', () => {
      hoverIsActive = false

      currentId = hoveredId;
      /* helperText.querySelector('.current-number').innerHTML = currentId */

      gsap.to(hoverImageWrapper, {
        opacity: 0,
        duration: 0.2
      })
    })

    accordionRows.forEach(row => {
      row.addEventListener('mouseenter', (e) => {
        hoveredId = e.target.getAttribute('data-index')

        hoverImages.forEach(image => {
          image.classList.remove('opacity-100')
          //find image with the currentId
          if (image.getAttribute('data-index') == hoveredId) {

            image.style.zIndex = zIndex
            zIndex++

            image.classList.add('opacity-100')
          }
        })
      })
    });
  }

  initHoverImage()
};

const initPageLoader = () => {
  const pageLoaderContainer = document.querySelector(".loader-container")
  const dividerContainer = pageLoaderContainer.querySelector('.divider-container')
  const loaderNumber = pageLoaderContainer.querySelector(".number")
  const tl = gsap.timeline()
  let animInterval;


  tl.to(pageLoaderContainer, {
    backgroundColor: "black",
    duration: 3,
    delay: 1,
    ease: "Power1.easeInOut",
  }, 0).to(dividerContainer, {
    width: "100%",
    duration: 3,
    ease: "Power1.easeInOut",
    delay: 1,
    onUpdate: () => {
      let tlProgress = tl.progress() * 100
      loaderNumber.innerHTML = tlProgress.toFixed(0)
    },
    onComplete: () => {
      gsap.to(pageLoaderContainer, {
        webkitClipPath: "inset(0% 0% 100% 0%)",
        clipPath: "inset(0% 0% 100% 0%)",
        duration: 1,
        delay: 0.5,
        ease: "Power3.easeInOut",
      })

      document.querySelector('body').classList.remove("overflow-hidden")
      initSwiper()
      initHeroParallax()
    }
  }, 0)
}

const initHeroParallax = () => {
  const heroSection = document.querySelector('.hero-section')

  const calculateHeroParallax = () => {
    let duration = window.innerHeight / 2
    let pixels = window.pageYOffset
    let progress = pixels / duration;

    let translateYVal = window.innerHeight * progress

    heroSection.style.transform = `translate3D(0px, ${-translateYVal}px, 0px)`

  }
  calculateHeroParallax()
  window.addEventListener('scroll', calculateHeroParallax)
  window.addEventListener('resize', calculateHeroParallax)
}

const initBlurbs = () => {
  const blurbs = document.querySelectorAll('.tiles-section .tile')

  blurbs.forEach(clickedBlurb => {
    clickedBlurb.addEventListener('click', (e) => {
      if (window.innerWidth > 1439) {
        return
      }
      if (e.target.classList.contains('active')) {
        e.target.classList.remove('active')
      } else {
        blurbs.forEach(blurb => {
          blurb.classList.remove('active')
        })
        clickedBlurb.classList.toggle('active')
      }
    })
  });



  window.addEventListener('resize', () => {
    blurbs.forEach(blurb => {
      blurb.classList.remove('active')
    })
  })
}

window.onload = () => {
  /* initTextLoop()*/
  initBlurbs()
  initMenu()
  /* initParallaxImages() */
  initScrollTriggers()
  initAccordion()
  initPageLoader()
}

/* 
window.onscroll = () => {
  initParallaxImages()
}
 */